import { ProductsWrapper } from "components/ProductList/ProductList.style";
import styled from "styled-components";
import {LoaderWrapper} from "components/common";
import {CartPromotionProductListModalWrapper} from "components/CartProductList/CartProductList.style";
import {CustomModalContentWrapper} from "components/ui/styled/common.styles";

export const PaymentModalWrapper = styled(CustomModalContentWrapper)<{
  additionalPaymentStep: boolean
  paymentMethodsStep: boolean
}>`
  padding: ${({additionalPaymentStep}) => additionalPaymentStep ? '50px 60px 0px' : '50px 72px 40px'};
  
  min-height: ${({additionalPaymentStep, paymentMethodsStep}) => additionalPaymentStep ? '650px' : paymentMethodsStep ? '846px' : '786px'};
  max-height: ${({additionalPaymentStep}) => additionalPaymentStep ? '780px' : 'auto'};
  min-width: ${({additionalPaymentStep}) => additionalPaymentStep ? '1014px' : '1000px'};
  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  justify-content: ${({paymentMethodsStep}) => paymentMethodsStep ? 'space-between' : 'flex-start'};
  
  .modal-header {
    ${({ theme: { fonts } }) => fonts.headerS};
    color: ${({ theme: { colors } }) => colors.textBlack};
    margin-bottom: 24px;
  }

  .modal-description {
    max-width: 481px;
    ${({ theme: { fonts } }) => fonts.subheaderS};
    color: ${({ theme: { colors } }) => colors.gray};
    margin-bottom: 28px;
    
    &.error-message {
      white-space: pre-wrap;
      color: ${({ theme: { colors } }) => colors.red};
    }
  }

  .payment-buttons {
    display: flex;

    .red-button {
      margin-inline-start: 8px;
    }
  }


  @media (max-width: 1090px) {
    min-width: 100%;
    padding: ${({additionalPaymentStep}) => additionalPaymentStep ? '50px 24px 0px' : '50px 24px 40px'};
  }

  @media (max-height: 800px) {
    min-height: ${({additionalPaymentStep, paymentMethodsStep}) => additionalPaymentStep ? '650px' : paymentMethodsStep ? '770px' : '770px'};
  }

  @media (max-width: 760px) {
    .ant-col-sm-12 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
`;

export const PaymentMethodsWrapper = styled.div<{
  methodCount: number
}>`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  
  .modal-description {
    margin-top: 30px;
    margin-bottom: 0;
    font-family: Inter;
    white-space: pre-line
  }
  
  .payment-methods-col {
    min-width: 288px;
  }
  
  .payment-methods-container {
    //margin-bottom: 125px;
    //margin-top: 125px;
    min-width: 100%;
    flex: 1;
    
    justify-content: ${({methodCount}) => methodCount % 3 === 2 ? 'flex-start' : 'center'};
    align-items: ${({methodCount}) => methodCount <= 3 ? 'center' : 'center'};

    //&.align-center {
    //}
    //
    //&.vertical-align-center {
    //  align-items: center;
    //}
  }
`

export const AdditionalProductsWrapper = styled(CartPromotionProductListModalWrapper)`{
  .modal-header {
    padding: 0;
    margin-bottom: 48px;
  }
  
  ${ProductsWrapper} {
    padding-block: 0 150px;
    padding-inline: 0;
    margin: 0;
    
    &::-webkit-scrollbar-track-piece:end {
      background: transparent;
      margin-bottom: 150px;
    }
  }
  
  .payment-buttons {
    position: absolute;
    bottom: 40px;
  }
}`

export const PaymentMethodWrapper = styled.div`
  cursor: pointer;
  padding-block: 16px 20px;
  padding-inline: 20px;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${({ theme: { colors } }) => colors.lightGray};
  border-radius: 24px;


  ${({ theme: { fonts } }) => fonts.subheaderMBold};

  text-align: center;

  color: ${({ theme: { colors } }) => colors.textBlack};
  
  @media (max-width: 760px) {
    min-width: 220px;
    max-width: 220px;
  }
  
  &.selected-method {
    background: ${({ theme: { colors } }) => colors.green};  
    color:  ${({ theme: { colors } }) => colors.white};
  }
  
  .mock-image {
    width: 60px;
    height: 60px;

    background: ${({ theme: { colors } }) => colors.green};
    border-radius: 100px;
  }
  
  .method-image {
    width: 60px;
    height: 60px;
    
    img {
      background: white;
      width: 100%;
      height: 100%;
      border-radius: 32px;
      object-fit: cover;
      
      &.without-radius {
        border-radius: 8px;
      }

      &.without-back {
        background: transparent;
      }
    }
  }
`

export const WriteOffBonusWrapper = styled.div<{
  showPaymentMethodsBlock?: boolean
}>`{
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  flex: 1;
  width: 100%;
  
  .modal-header {
    margin-bottom: 24px;
  }
  
  .modal-description {
    margin-bottom: ${({ showPaymentMethodsBlock }) => showPaymentMethodsBlock ? 0 : 72}px;
  }
  
  .error-text {
    ${({ theme: { fonts } }) => fonts.subheaderS};
    color: #D92351;
    text-align: center;
  }

  .description-text {
    ${({ theme: { fonts } }) => fonts.subheaderS};
    color: #797D80;
    text-align: center;
  }
  
  .bonus-action-container {
    flex: 1;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: ${({ theme: { colors } }) => colors.textBlack};
    
    &.show-payment-methods-block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .bonus-slider {
      margin-bottom: 94px;
      height: 70px;
      
      .ant-slider-mark {
        margin-top: 48px;
      }
      
      .ant-slider-dot {
        display: none;
      }
      
      .ant-slider-mark-text {
        ${({ theme: { fonts } }) => fonts.subheaderS};

        white-space: nowrap;
        text-align: right;
      }
      
      .ant-slider-handle {
        &:before, &:after {
          display: none;
        }

        width: 52px;
        height: 52px;
        background: ${({ theme: { colors } }) => colors.coralRed};
        transform: ${({ theme: { rtl } }) => rtl ? 'translate(26px, -14px)' : 'translate(-26px, -14px)'}!important;
        border-radius: 26px;
      }
      
      .ant-slider-disabled {
        .ant-slider-handle {
          background: ${({ theme: { colors } }) => colors.gray};
        }
      }
    }
    
    .bonus-info {
      padding: 6px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .old-price {
        text-decoration: line-through;
        margin-inline-end: 24px;
        color: ${({ theme: { colors } }) => colors.gray};
      }
    }
  }
  
  .ant-divider {
    background: ${({ theme: { colors } }) => colors.lightGray};
  }
}`

export const FinishPaymentWrapper = styled.div<{
  isError?: boolean
}>`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  flex: 1;
  width: 100%;
  
  .loader-container {
    position: absolute;
    z-index: 90;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 60px;
    width: 100%;
    height: 100%;
    max-height: 656px;
    background: ${({ theme: { colors } }) => colors.white};
    ${LoaderWrapper} {
      transform: translateY(-50px);
    }
    
    &.small {
      top: 4px;
      width: 200px;
      height: 200px;
      max-height: 200px;
      background: transparent;
      
      ${LoaderWrapper} {
        transform: translateY(0);
        
        svg {
          width: 200px;
          height: 200px;
          
          circle {
            stroke-width: 10px;
            r: 80px
          }
        }
      }
    }
  }
  
  .modal-header {
    margin-bottom: 42px;
  }
  
  .method-description {
    display: flex;
    margin-bottom: 68px;
    align-items: center;
    max-width: 650px;
    min-height: 200px;
    
    // .method-image {
    //   margin-inline-end: 32px;
    //   min-width: 200px;
    //   max-width: 200px;
    //   height: 200px;
    //   background:  ${({ theme: { colors } }) => colors.white};
    //   border: 10px solid ${({isError}) => isError ? '#D92351' : '#EBECED'};
    //   border-radius: 100px;
    //   overflow: hidden;
    //
    //   &.method-with-qr-code {
    //     border-radius: 0;
    //   }
    //  
    //   img {
    //     width: 174px;
    //     height: 174px;
    //     margin-top: 31px;
    //     margin-inline-start: 23px;
    //   }
    // }
    
    .method-image {
      position: relative;
      margin-inline-end: 32px;
      min-width: 200px;
      max-width: 200px;
      height: 200px;
      background:  ${({ theme: { colors } }) => colors.white};
      border-radius: 100px;
      overflow: hidden;
  
      &.method-with-qr-code {
        border-radius: 0;
      }
      
      .anticon {
        font-size: 108px;
        color: ${({ theme: { colors } }) => colors.red};
      }
  
      .img-wrapper {
        border: 10px solid ${({isError}) => isError ? '#D92351' : '#EBECED'};
        border-radius: 1000px;
        overflow: hidden;
        height: 100%;
  
        display: flex;
        align-items: center;
        justify-content: center;
  
        img {
          width: 174px;
          height: 174px;
          object-fit: contain;
          margin-top: 31px;
          margin-inline-start: 23px;
        }

        img.yandex-badge {
          width: 179px;
          height: 179px;
          object-fit: contain;
          margin-top: 0px;
          margin-inline-start: 0px;
        }
        
        &.circle-image {
          img {
            width: 105%;
            height: 105%;
          }
        }
      }
    }
    
    .method-text {
      ${({ theme: { fonts } }) => fonts.subheaderS};
      color: ${({isError}) => isError ? '#D92351' : '#797D80'};
      font-size: 20px;
      text-align:  ${({isError}) => isError ? 'center' : 'left'};
      white-space: pre-wrap;
    }
  }
  
  .payment-info-container {
    flex: 1;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: ${({ theme: { colors } }) => colors.textBlack};

    .ant-divider {
      background: ${({ theme: { colors } }) => colors.lightGray};
    }

    .payment-info {
      padding: 6px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      
      .alert {
        color: ${({ theme: { colors } }) => colors.red};
      }
      
      .success {
        color: ${({ theme: { colors } }) => colors.green};
        margin-inline-end: 24px;
      }
    }
  }
`
