import React, {useMemo, useState} from "react";
import {useTranslate} from "@refinedev/core";
import {Col, Row} from "antd";
import {useCartContext, useCatalogData, useGlobalSettings, usePayment, useUI} from "context";
import {ICart, ICartProduct, IProduct, IPromotion} from "types";
import {PromotionWrapper} from "components/PromotionList/PromotionList.style";
import {CustomButton, Modal} from "components/ui";
import {getImageUrl, unitsByCode} from "libs/utils";
import {PromoCodeTip} from "components/PromoCodeTip";
import {ProductList} from "components/ProductList";
import {useChangeProductCount} from "context/hooks";
import {CartMinus, CartPlus, Close} from "components/common/Icons";
import {ProductImage} from "components/ProductImage";
import {EBonusSystemType} from "types/constants";
import {
  CartBonusTooltipWrapper,
  CartProductListWrapper,
  CartProductWrapper,
  CartPromotionProductListModalWrapper
} from "./CartProductList.style";
import {MoneyUnits} from "components/common/MoneyUnits";
import {LocaleName} from '../common/LocaleName';
import {LocaleDescription} from '../common/LocaleDescription';


export const CartProductList: React.FC = () => {
  const t = useTranslate()

  const { settings } = useGlobalSettings();
  const { cart, products } = useCartContext();
  const { bonusCard } = usePayment()
  const { toggleDisplayBonusAuthModal } = useUI();

  const { promotions, products: allProducts } = useCatalogData()

  const [ selectedPromotion, setSelectedPromotion] = useState<IPromotion | null>(null);

  return (
    <CartProductListWrapper>
      <Row gutter={[0, 8]} className={"content-wrapper"}>
        <div style={{width: '100%', flex: '1'}}>
          <Row gutter={[8, 8]} className={"row-with-a-cards"}>
            {products?.map((product: ICartProduct, indx: number) => {
              return (
                <Col span={12} xl={12} lg={24} md={24} sm={24} xs={24} key={indx} className={"product-card-in-cart"}>
                  <CartProduct cart={cart} product={product} isMarked={!!settings?.productMarkingEnabled && product?.isMarked}/>
                </Col>
              )
            })}
          </Row>
        </div>

        {/*{promotions.filter(promo => promo.productIds.some(pId => products.some(p => p.id === pId && p.discount?.id === promo?.id))).map((promotion) => (*/}
        {/*  <Col span={12} xl={12} lg={24} md={24} sm={24} xs={24} key={promotion.id}>*/}
        {/*    <PromotionWrapper className={"cart-version"}*/}
        {/*                      onClick={() => setSelectedPromotion(promotion)}*/}
        {/*                      imageUrl={getImageUrl(promotion?.imageUrl)}>*/}
        {/*      <div className={"promotion-container"}>*/}
        {/*        <div className={"promotion-title"}>*/}
        {/*          {!promotion?.imageUrl && (*/}
        {/*            <LocaleName entity={promotion} langs={settings?.languages || []}/>*/}
        {/*          )}*/}
        {/*        </div>*/}
        {/*        <CustomButton onClick={() => setSelectedPromotion(promotion)} className={"white-button"}>{t("buttons.select")}</CustomButton>*/}
        {/*      </div>*/}

        {/*      /!*<div className={"promotion-img-container"}>*!/*/}
        {/*      /!*  {promotion?.imageUrl ? (*!/*/}
        {/*      /!*    <img className={"promotion-img"} src={getImageUrl(promotion?.imageUrl)} alt={promotion?.name}/>*!/*/}
        {/*      /!*  ) : (*!/*/}
        {/*      /!*    <div className={"image-not-exist"}/>*!/*/}
        {/*      /!*  )}*!/*/}
        {/*      /!*</div>*!/*/}
        {/*    </PromotionWrapper>*/}
        {/*  </Col>*/}
        {/*))}*/}

        <Row gutter={[8, 8]} className={"row-with-a-cards"}>
          <Col span={12} xl={12} lg={24} md={24} sm={24} xs={24}><PromoCodeTip/></Col>

          {!bonusCard?.cardId && ((settings?.posTerminal?.paymentEnabled || settings?.payWithBonusPointsOnly) && (settings?.bonusSystemType === EBonusSystemType.GOPOEDIM)) && (
            <Col span={12} xl={12} lg={24} md={24} sm={24} xs={24} key={"bonus-card"}>
              <CartBonusTooltipWrapper className={"cart-version"}>
                <div className={"cart-bonus-tooltip-container"}>
                  <div className={"cart-bonus-tooltip-title"}>
                    {t("cart.bonus.cartTooltip.text")}
                  </div>
                  <CustomButton onClick={() => toggleDisplayBonusAuthModal(true)} className={"violet-button"}>
                    {t("cart.bonus.button")}
                  </CustomButton>
                </div>

                <div className={"cart-bonus-tooltip-img-container"}>
                  <img className={"cart-bonus-tooltip-img"} src={"images/bonus-card-img.png"} alt={""}/>
                </div>
              </CartBonusTooltipWrapper>
            </Col>
          )}
        </Row>
      </Row>

      <Modal visible={!!selectedPromotion} setVisible={() => setSelectedPromotion(null)}>
        <CartPromotionProductListModalWrapper>
          <div className={"modal-header"}>{t("cart.promotionProductsModal.title")}</div>
          <div className={"modal-text"}>
            <LocaleDescription entity={selectedPromotion}/>
          </div>

          <ProductList settings={settings} products={selectedPromotion?.productIds?.reduce((arr: IProduct[], pId) => {
            const product = allProducts?.find(p => p.id === pId && !p.onlyScan)
            return product ? [...arr, product] : arr
          }, []) || []
          }/>

          <CustomButton className={"red-button"} wrapClassName={"wrap-absolute"} onClick={() => setSelectedPromotion(null)}>
            {t("modal.buttons.ready")}
          </CustomButton>
        </CartPromotionProductListModalWrapper>
      </Modal>
    </CartProductListWrapper>
  )
}

const CartProduct: React.FC<{ product: ICartProduct, cart: ICart, isMarked: boolean }> = React.memo(({ product, cart, isMarked }) => {

  const { settings } = useGlobalSettings();

  const t = useTranslate()
  const line = cart?.lines?.find(l => l?.product_id === product.id);
  const { onDeleteItem } = useCartContext();
  const { promotions } = useCatalogData();

  const {
    productQuantity: addingProductQuantity,
    onAddItemWithDebounce,
    onDeleteItemWithDebounce,
  } = useChangeProductCount(product, settings)

  const quantity = useMemo(() => product.quantity + addingProductQuantity <= 0 ? 0 : product.quantity + addingProductQuantity, [addingProductQuantity, product])

  return (
    <CartProductWrapper key={product?.id} isDiscount={!!line?.discount}>
      <div className={"removed-button"}
           onClick={() => onDeleteItem(product.id, 'item_removed_by_user', product.quantity)}>
        <Close color={"#797D80"}/>
      </div>
      <div className={"cart-product-content"}>
        <div className={"cart-product-header"}>
          <ProductImage discount={!!promotions?.find(pr => pr.productIds.some(pId => pId === product.id))}
                        isPromo={!!line?.discount} showBadge={false}
                        product={product} className={"cart-preview"}/>

          <div className={"cart-product-content-info"}>
            <div className={"product-title"}>
              <LocaleName entity={product} langs={settings?.languages || []}/>
            </div>
            <div className={"product-info"}>
              {!!product?.weight && (
                <span style={{marginInlineEnd: '24px'}}>{product?.weight}&nbsp;{t(`product.${unitsByCode(product?.unit)}`)}</span>)}
              {!!product?.calories && (
                <span>{product?.calories}&nbsp;{t("product.kcal")}</span>)}
            </div>
          </div>
        </div>
        <div className={"manage-price-container"}>
          {!line?.discount ? (
            <div className={"product-price"}>{isMarked ? line?.product_total_price || product?.price : (line?.amount || product?.price)}&nbsp;<MoneyUnits/></div>
          ) : (
            <div className={"flex flex-1"} style={{justifyContent: 'flex-end'}}>
              <div className={"flex price-with-discount-wrapper"}>
                <div className={"product-price discount-price"}>{isMarked ? line?.product_total_price : line?.amount}&nbsp;<MoneyUnits/></div>
                <div className={"product-price old-price"}>{isMarked ? line?.product_initial_price || product?.price : line?.initial_amount || product?.price}&nbsp;<MoneyUnits/></div>
              </div>
            </div>
          )}

          <div className={"counter-container"}>
            <div className={"counter-button"} onClick={onDeleteItemWithDebounce}><CartMinus/></div>
            <div className={"counter-text"}>{quantity}</div>
            <div className={"counter-button"} onClick={onAddItemWithDebounce}><CartPlus/></div>
          </div>
        </div>
      </div>
    </CartProductWrapper>
  )
})
