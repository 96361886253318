import {IPaymentMethod, IPaymentStepsProps} from "types";
import React, {useEffect, useMemo, useState} from "react";
import {Col, Row} from "antd";
import {useTranslate} from "@refinedev/core";
import {CustomButton} from "components/ui";
import {useCartContext, useGlobalSettings, usePayment} from "context";
import {PaymentMethodsWrapper, PaymentMethodWrapper} from "components/Payment/Payment.style";
import {EBonusSystemType, EPaymentMethods, EPaymentSteps} from "types/constants";
import {BankCartIcon, BonusIcon, OnLunchIcon, QRCodeIcon, SBPIcon, YandexBadgeIcon, YandexFoodIcon} from "./icons";
import {BonusCardAuth} from "components/BonusCardAuth";
import {getMaxSliderBonusesValue, useGetBrandName, useGetMaxBonusesValue} from "libs/hooks";


export const SelectPaymentMethod: React.FC<IPaymentStepsProps> = React.memo(({ goNextStep, goPreviousStep }) => {

  const t = useTranslate();
  const { cart } = useCartContext();

  const { settings } = useGlobalSettings()
  const { bonusCard, selectedPaymentMethod, isErrorBonusCard, isCheckingBonusCardNumber, onClearBonusCard, onSelectPaymentMethod,
    onChangeSpentBonusCount } = usePayment()

  const brandName = useGetBrandName();

  const [displayKeyboard, setDisplayKeyboard] = useState<boolean>(false)

  const bonusTypes = useMemo(() => Object.values(EBonusSystemType), [EBonusSystemType])
  const isSelectedMethodBonus = useMemo(() => Object.values(EBonusSystemType).includes(selectedPaymentMethod as unknown as EBonusSystemType), [selectedPaymentMethod, EBonusSystemType])

  const { canFullPayment, payWithMaxBonuses } = useGetMaxBonusesValue()

  useEffect(() => {
    setDisplayKeyboard(isSelectedMethodBonus)
  }, [isSelectedMethodBonus])

  const paymentMethods: IPaymentMethod[] = useMemo(() => (
    [
      {
        title: t("payment.methods.card"),
        key: EPaymentMethods.PAYMENT_TYPE_CARD,
        visible: !!settings?.posTerminal?.paymentEnabled,
        icon: <BankCartIcon isSelected={selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_CARD}/>
      },
      {
        title: t("payment.methods.sbp"),
        key: EPaymentMethods.PAYMENT_TYPE_SBP,
        visible: !!settings?.sbpQrEnabled,
        icon: <SBPIcon/>
      },
      {
        title: t("payment.methods.yandexFood"),
        key: EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA,
        visible: !!settings?.yEdaEnabled,
        icon: <YandexFoodIcon/>
      },
      {
        title: t("payment.methods.yandexQR"),
        key: EPaymentMethods.PAYMENT_TYPE_YANDEX_QR,
        visible: !!settings?.yQrEnabled,
        icon: <QRCodeIcon isSelected={selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_YANDEX_QR}/>
      },
      {
        title: t("payment.methods.yandexBadge"),
        key: EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE,
        visible: !!settings?.ybadgeEnabled,
        icon: <YandexBadgeIcon/>
      },
      {
        title: t("payment.methods.launch"),
        key: EPaymentMethods.PAYMENT_TYPE_ON_LUNCH,
        visible: !!settings?.nalunchEnabled,
        icon: <OnLunchIcon/>
      },
      {
        title: t(`payment.methods.${settings?.bonusSystemType}`, {
          brandName,
          interpolation: {
            escapeValue: false,
          }
        }),
        key: settings?.bonusSystemType || EBonusSystemType.GOPOEDIM,
        visible: !!settings?.posTerminal?.paymentEnabled && !!settings?.bonusSystemType,
        icon: <BonusIcon bonusSystemType={settings?.bonusSystemType} selectedPaymentMethod={selectedPaymentMethod as unknown as EBonusSystemType}/>
      }
    ].filter(m => m.visible)
  ), [settings, selectedPaymentMethod, t])

  const handlePaymentMethodSelect = (method: EPaymentMethods | EBonusSystemType | null) => {
    if (bonusTypes.includes(method as unknown as EBonusSystemType)) {
      onSelectPaymentMethod(settings?.bonusSystemType || null)
    } else {
      onSelectPaymentMethod(method)
    }

    if (payWithMaxBonuses && bonusCard?.userId && canFullPayment) {
      goNextStep()
      return;
    }

    if (Object.values(EBonusSystemType).includes(method as unknown as EBonusSystemType)) {
      if (displayKeyboard || bonusCard?.cardId) {
        goNextStep(EPaymentSteps.WRITE_OFF_BONUSES)
      } else {
        //setDisplayKeyboard(true)
      }
    } else {
      goNextStep()
    }
  }

  const handleGoToPaymentWithBonuses = (step: EPaymentSteps, allBonus = 0) => {
    const maxValue = getMaxSliderBonusesValue(cart, settings, allBonus);
    if (payWithMaxBonuses) {
      onChangeSpentBonusCount(maxValue)
    }
    goNextStep(payWithMaxBonuses && (cart?.total_amount - maxValue === 0) ? EPaymentSteps.FINISH_PAYMENT : step)
  }

  return (
    <>
      <div className={"modal-header"}>{(
        (isSelectedMethodBonus && displayKeyboard) || settings?.payWithBonusPointsOnly) ?
        t(`payment.methods.${settings?.bonusSystemType}`, {
          brandName,
          interpolation: {
            escapeValue: false
          }
        }) :
        t("payment.methods.header")}</div>

      {(settings?.payWithBonusPointsOnly || displayKeyboard) ? (
        <>
          {!isErrorBonusCard || isCheckingBonusCardNumber ? (
            <div className={"modal-description"}>{t(`payment.methods.description.${settings?.bonusSystemType}`, {
              brandName,
              interpolation: {
                escapeValue: false,
              }
            })}</div>
          ) : (
            <div className={"modal-description error-message"}>{t("payment.methods.errors.bonusCardNotFound")}</div>
          )}
          <BonusCardAuth displayKeyboard={displayKeyboard}
                         onChangePaymentStep={handleGoToPaymentWithBonuses}
                         onChangeDisplayKeyboard={(v) => setDisplayKeyboard(v)} />
        </>
      ) : (
        <PaymentMethodsWrapper methodCount={paymentMethods?.length}>
          <Row className={`payment-methods-container`} gutter={[8, 8]}>
            {!settings?.payWithBonusPointsOnly && (
              <>
                {paymentMethods.map(m => (
                  <Col xs={12} sm={12} md={8} lg={8} key={m.key} className={"payment-methods-col"}>
                    <PaymentMethodWrapper
                      className={m.key !== selectedPaymentMethod ? '' : 'selected-method'}
                      onClick={() => handlePaymentMethodSelect(selectedPaymentMethod === m.key ? null : m.key)}>
                      {m.icon ? m.icon : <div className={"mock-image"}/>}
                      <span>{m.title}</span>
                    </PaymentMethodWrapper>
                  </Col>
                ))}
              </>
            )}
          </Row>

          {/*{!!settings?.posTerminal?.paymentEnabled && (settings?.bonusSystemType === EBonusSystemType.GOPOEDIM) && settings?.goPoedimBonusSystemUrl && (*/}
          {/*  <>*/}
          {/*    <Row className={"modal-description"}>*/}
          {/*      {t("cart.bonus.register")}*/}
          {/*    </Row>*/}

          {/*    <Row>*/}
          {/*      <Col span={24} className={"angled-borders"}>*/}
          {/*        <QRCode size={132}*/}
          {/*                errorLevel="H"*/}
          {/*                value={settings?.goPoedimBonusSystemUrl || ''}*/}
          {/*                type={"svg"}*/}
          {/*                icon={"images/logo-color.svg"}*/}
          {/*                iconSize={30}*/}
          {/*                style={{*/}
          {/*                  direction: 'ltr',*/}
          {/*                  height: '100%',*/}
          {/*                  width: '100%',*/}
          {/*                  border: `10px solid`,*/}
          {/*                  borderImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11' width='11' height='11'><g fill='%23767676'><rect width='1' height='5'/><rect y='6' width='1' height='5'/><rect x='10' y='6' width='1' height='5'/><rect x='10' width='1' height='5'/><rect width='5' height='1'/><rect y='10' width='5' height='1'/><rect x='6' y='10' width='5' height='1'/><rect x='6' width='5' height='1'/></g></svg>") 5`,*/}
          {/*                  padding: 0*/}
          {/*                }}*/}
          {/*        />*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </>*/}
          {/*)}*/}

        </PaymentMethodsWrapper>
      )}

      <div className={"payment-buttons"}>
        {settings?.payWithBonusPointsOnly ? (
          <CustomButton className={"gray-button"} onClick={() => goPreviousStep()}>{t("payment.buttons.back")}</CustomButton>
        ) : (
          <>
            {displayKeyboard ? (
              <CustomButton disabled={isCheckingBonusCardNumber} className={"gray-button"} onClick={() => {
                onClearBonusCard()
                onSelectPaymentMethod(null)
                setDisplayKeyboard(false)
              }}>{t("payment.buttons.back")}</CustomButton>
            ) : (
              <CustomButton className={"gray-button"} onClick={() => goPreviousStep()}>{t("payment.buttons.back")}</CustomButton>
            )}
          </>
        )}
      </div>
    </>
  )
})
