import {useEffect, useState} from "react";
import {useCustomMutation} from "@refinedev/core";
import {API_URL, EBonusSystemType} from "types/constants";
import {ISettings, useGlobalSettings} from "context/globalSettings";
import {IProduct} from "types";
import {useCartContext, usePayment} from "context";
import urlJoin from "url-join";
import {normalizeCurrencyValue} from '../../libs/utils';


export const useSendEmail = () => {
  const {mutateAsync: sendEmail, reset: resetEmailRequestData, error: sendingEmailError } =
    useCustomMutation<any>({});

  const handleEmailSend = async (email: string, orderId: string) => {
    if (!orderId) {
      return;
    }
    try {
      await sendEmail(
        {
          url: urlJoin(API_URL, '/payment/set_email_for_receipt/'),
          method: "post",
          values: {
            email,
            order_id: orderId,
          },
          errorNotification: false,
          successNotification: false
        }
      );
    } catch (e) {
      console.log(e)
    }
  }

  return {
    sendingEmailError,
    resetEmailRequestData,
    handleEmailSend
  }
}

export const useBonusCard = () => {
  const {settings} = useGlobalSettings()

  const {mutateAsync: getBonusCardAsync, reset: resetBonusCardRequestData, isLoading: isCheckingBonusCardNumber } =
    useCustomMutation<any>();

  const [spentBonusCount, setSpentBonusCount] = useState<number>(0);
  const [bonusCardNumber, setBonusCardNumber] = useState('');
  const [bonusCard, setBonusCard] = useState<any>({})
  const [isErrorBonusCard, setIsErrorBonusCard] = useState<boolean>(false)

  useEffect(() => {
    if (!bonusCardNumber) {
      setIsErrorBonusCard(false)
      setBonusCard({})
      setSpentBonusCount(0)
    }
  }, [bonusCardNumber])

  const handleValidateBonusCard = async (value?: string) => {
    //тут будет валидация карты - пока заглушка
    return await handleBonusCardGet(value || '')
  }

  const handleBonusCardGet = async (value: string) => {
    let url = urlJoin(API_URL, '/payment/obedru_signin_by_card/');
    if (settings?.bonusSystemType === EBonusSystemType.GOPOEDIM) {
      url = urlJoin(API_URL, '/payment/gopoedim_signin_by_bonus_card/')
    }

    const number = value || bonusCardNumber

    if (!number?.length) {
      return;
    }

    try {
      return await getBonusCardAsync(
        {
          url,
          method: "post",
          values: { card_number: number},
          errorNotification: false,
          successNotification: false
        },
        {
          onSuccess: ({data}) => {
            if (data?.error) {
              setIsErrorBonusCard(true)
            } else {
              setIsErrorBonusCard(false)
              setBonusCard({
                cardId: data?.idCard || data?.card,
                allBonus: data?.PointOnCart ? data?.PointOnCart : !data?.userBalance100 ? 0 : normalizeCurrencyValue(data?.userBalance100 / 100),
                userId: data?.idUser || data?.userId,
                userName: data?.nameUser || data?.userName,
                firstName:  data?.firstName || '',
                lastName:  data?.lastName || '',
              })
            }
          },
          onError: (error) => {
            setIsErrorBonusCard(!!error)
            throw error
          },
        },
      );
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const handleClear = () => {
    if (!!bonusCard?.cardId) {
      resetBonusCardRequestData()
    }
    setIsErrorBonusCard(false)
    setBonusCard({})
    setBonusCardNumber('')
    setSpentBonusCount(0)
  }

  return  {
    handleValidateBonusCard, spentBonusCount, setSpentBonusCount, bonusCard, bonusCardNumber, setBonusCardNumber,
    isErrorBonusCard, setIsErrorBonusCard, handleBonusCardClear: handleClear, isCheckingBonusCardNumber
  }
}

export const useDebounce = (value: any) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      if (!value) {
        setDebouncedValue(value);
        return;
      }
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, 220);

      return () => {
        clearTimeout(handler);
      };
    }, [value]
  );

  return debouncedValue;
};

export const useChangeProductCount = (product: IProduct | null, settings: ISettings | null) => {
  const [count, setCount] = useState<number>(0)

  const debouncedCount = useDebounce(count);

  const { onAddItem, onDeleteItem } = useCartContext();
  const { onClearBonusCard } = usePayment();

  useEffect(() => {
    if (!!debouncedCount && product) {
      if (debouncedCount > 0) {
        setCount(0)
        onAddItem(product, debouncedCount,'')
          //.then(() => setCount(0))
      } else {
        setCount(0)
        onDeleteItem(product.id, 'item_removed_by_user', -1 * debouncedCount)
          .then((payloadBasket: any) => {
            if (Object.keys(payloadBasket)?.length === 0) {
              onClearBonusCard()
            }
            //setCount(0)
          })
      }
    }
  }, [debouncedCount])


  const _addItem = async () => {
    if (product?.isMarked && settings?.productMarkingEnabled) {
      await onAddItem(product, 1,'')
    } else {
      setCount(c => c + 1)
    }
  }

  const _deleteItem = async () => {
    if (product?.isMarked && settings?.productMarkingEnabled) {
      await onDeleteItem(product?.id, 'item_removed_by_user', 1)
    } else {
      setCount(c => c - 1)
    }
  }

  return {
    productQuantity: count,
    onAddItemWithDebounce: _addItem,
    onDeleteItemWithDebounce: _deleteItem,
  }
}
