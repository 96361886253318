import React, {useEffect, useMemo, useState} from "react";
import {
  BonusCardTipWrapper,
  CartButtons,
  CartHeader,
  CartWrapper,
  ConfirmResetCartModalWrapper,
  EmptyCartWrapper
} from "./Cart.style";
import plural from "plural-ru"
import {useGetLocale, useTranslate} from "@refinedev/core";
import {Col, Row} from "antd";
import {useCartContext, useGlobalSettings, usePayment, useUI} from "context";
import {ClearCart} from "components/common/Icons";
import {CustomButton, Modal} from "components/ui";
import {PaymentModal} from "components/Payment";
import {EBonusSystemType, ERRORS} from "types/constants";
import { CartProductList } from "components/CartProductList/CartProductList";
import {MoneyUnits} from "components/common/MoneyUnits";
import {useGetMaxBonusesValue} from "libs/hooks";


export const Cart: React.FC = () => {
  const t = useTranslate()
  const locale = useGetLocale();
  const currentLocale = locale();

  const { openCatalog, toggleDisplaySearchResults, toggleDisplayBonusAuthModal, openKeyBoard } = useUI();
  const { onOpenDisplayPayment, bonusCard, onClearBonusCard } = usePayment()
  const { settings } = useGlobalSettings()
  const { canFullPayment, payWithMaxBonuses } = useGetMaxBonusesValue()

  const [displayConfirm, setDisplayConfirm] = useState<boolean>(false);
  const [displayPromoErrorConfirm, setDisplayPromoErrorConfirm] = useState<boolean>(false);

  const { cart, products, onResetCart, promoCodeError, onChangePromoCode, onApplyPromoCode, onSetPromoCodeError } = useCartContext()

  useEffect(() => {
    setDisplayPromoErrorConfirm(promoCodeError === ERRORS.PROMO_CODE_NOT_UNIQUE)
  }, [promoCodeError])

  const handleCartReset = async () => {
    await onResetCart('cart_cleared_by_user')
    onClearBonusCard()
    setDisplayConfirm(false)
  }

  const handlePromoCodeReset = async () => {
    onSetPromoCodeError(null);
    await onApplyPromoCode('')
  }

  const _openSearchBar = () => {
    toggleDisplaySearchResults(true);
    openKeyBoard()
  }

  const itemsCount = useMemo(() => products?.reduce((number, p) => p.quantity + number, 0), [products])

  if (!cart.lines?.length) {
    return (
      <EmptyCartWrapper>
        <div className={"empty-cart-text"}>{t("cart.empty.description")}</div>
        <Row gutter={[8, 8]} justify={"center"} className={"empty-cart-buttons"}>
          <Col>
            <CustomButton className={"white-button"} onClick={_openSearchBar}>
              {t("cart.empty.searchButton")}
            </CustomButton>
          </Col>
          <Col>
            <CustomButton className={"white-button"} onClick={() => openCatalog()}>
              {t("cart.empty.catalogButton")}
            </CustomButton>
          </Col>
        </Row>

        <Modal visible={displayPromoErrorConfirm} setVisible={() => {
          onSetPromoCodeError(null)
          onChangePromoCode('')
        }}>
          <ConfirmResetCartModalWrapper className={"small-width"}>
            <div className={"confirm-title"}>
              {t("cart.emptyCartWithPromoCode.title")}
            </div>

            <div className={"confirm-description"}>
              {t("cart.emptyCartWithPromoCode.description", {total_amount: cart.total_amount, money_unit: t(`layout.moneyUnits.${settings?.moneyUnits?.toLowerCase()}`) || settings?.moneyUnits})}
            </div>

            <div className={"confirm-buttons"}>
              <CustomButton className={"gray-button"} onClick={() => {
                onSetPromoCodeError(null)
                onChangePromoCode('')
              }}>{t("cart.emptyCartWithPromoCode.buttons.cancel")}</CustomButton>
              <CustomButton className={"red-button"} onClick={() => setDisplayPromoErrorConfirm(false)}>{t("cart.emptyCartWithPromoCode.buttons.confirm")}</CustomButton>
            </div>
          </ConfirmResetCartModalWrapper>
        </Modal>

        {!bonusCard?.cardId && settings?.goPoedimBonusesEnabled && ((settings?.posTerminal?.paymentEnabled || settings?.payWithBonusPointsOnly) && (settings?.bonusSystemType === EBonusSystemType.GOPOEDIM)) && (
          <BonusCardTipWrapper>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <div className={"bonus-tooltip-text-wrapper"}>
                  <div className={"bonus-tooltip-header"}>
                    {t("cart.bonus.emptyTooltip.header")}
                  </div>

                  <div className={"bonus-tooltip-text"}>
                    {t("cart.bonus.emptyTooltip.text")}
                  </div>
                </div>
              </Col>

              <Col span={12}>
                <div className={"col-flex-end"}>
                  <CustomButton className={"violet-button"} wrapClassName={"wrap-end"} onClick={() => toggleDisplayBonusAuthModal(true)}>
                    {t("cart.bonus.button")}
                  </CustomButton>
                </div>
              </Col>
            </Row>
          </BonusCardTipWrapper>
        )}
      </EmptyCartWrapper>
    )
  }

  return (
    <CartWrapper className={"dark-background"}>
      <CartHeader>
        <span className={"default-header"}>{t('cart.header')}</span>
        <span>
          <span className={"total-item-count"}>
            {currentLocale === "en" ? `${itemsCount} ${itemsCount === 1 ? t("cart.fulled.itemCountOne") : t("cart.fulled.itemCountOther")}` :
              `${itemsCount} ${plural(itemsCount,  t("cart.fulled.itemCountOne"), t("cart.fulled.itemCountFew"), t("cart.fulled.itemCountOther"))}`}
           </span>
          <span className={"reset-button"} onClick={() => setDisplayConfirm(true)}>
            <ClearCart/>
          </span>
        </span>
      </CartHeader>

      <CartProductList/>

      <CartButtons>
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <div className={"total-amount"}>
              <span>{t("cart.totalAmount")}</span>
              {(cart.total_amount === cart?.initial_amount) ? (
                <span>{cart.total_amount}&nbsp;<MoneyUnits/></span>
              ) : (
                <div className={"flex flex-1 initial-with-discount"}>
                  <div className={"product-price discount-price"}>{cart.total_amount}&nbsp;<MoneyUnits/></div>
                  <div className={"product-price old-price"}>{cart.initial_amount}&nbsp;<MoneyUnits/></div>
                </div>
              )}
            </div>
          </Col>
          <Col span={12}>
            <CustomButton className={"green-button big-size"} disabled={!!promoCodeError} onClick={() => onOpenDisplayPayment(!!(canFullPayment && payWithMaxBonuses))}>
              {settings?.payWithBonusPointsOnly ? t("cart.paymentButtonOnlyBonus") : t("cart.paymentButton")}
            </CustomButton>
          </Col>
        </Row>
      </CartButtons>

      <Modal visible={displayConfirm} setVisible={setDisplayConfirm}>
        <ConfirmResetCartModalWrapper className={"remove-items"}>
          <div className={"confirm-title"}>
            {t("cart.confirm.title")}
          </div>

          <div className={"confirm-description"}>
            {t("cart.confirm.description", {total_amount: cart.total_amount, money_unit: t(`layout.moneyUnits.${settings?.moneyUnits?.toLowerCase()}`) || settings?.moneyUnits})}
          </div>

          <div className={"confirm-buttons"}>
            <CustomButton className={"gray-button"} onClick={() => setDisplayConfirm(false)}>{t("cart.confirm.cancel")}</CustomButton>
            <CustomButton className={"red-button"} onClick={handleCartReset}>{t("cart.confirm.reset")}</CustomButton>
          </div>
        </ConfirmResetCartModalWrapper>
      </Modal>

      <PaymentModal/>

      <Modal visible={displayPromoErrorConfirm} setVisible={handlePromoCodeReset}>
        <ConfirmResetCartModalWrapper className={"small-width"}>
          <div className={"confirm-title"}>
            {t("cart.promoConfirm.title")}
          </div>

          <div className={"confirm-description"}>
            {t("cart.promoConfirm.description", {total_amount: cart.total_amount, money_unit: t(`layout.moneyUnits.${settings?.moneyUnits?.toLowerCase()}`) || settings?.moneyUnits})}
          </div>

          <div className={"confirm-buttons"}>
            <CustomButton className={"gray-button"} onClick={handlePromoCodeReset}>
              {t("cart.promoConfirm.buttons.cancel")}
            </CustomButton>
            <CustomButton className={"red-button"} onClick={handlePromoCodeReset}>{t("cart.promoConfirm.buttons.confirm")}</CustomButton>
          </div>
        </ConfirmResetCartModalWrapper>
      </Modal>

      <Modal visible={false} setVisible={() => {}}>
        <ConfirmResetCartModalWrapper>
          <div className={"confirm-title"}>
            {t("cart.discountConfirm.title")}
          </div>

          <div className={"confirm-description"}>
            {t("cart.discountConfirm.description", {total_amount: cart.total_amount, money_unit: t(`layout.moneyUnits.${settings?.moneyUnits?.toLowerCase()}`) || settings?.moneyUnits})}
          </div>

          <div className={"confirm-buttons"}>
            <CustomButton className={"gray-button"} onClick={() => setDisplayConfirm(false)}>{t("cart.discountConfirm.buttons.cancel")}</CustomButton>
            <CustomButton className={"red-button"} disabled={true} onClick={() => {
              setDisplayConfirm(false)
            }}>{t("cart.discountConfirm.buttons.confirm")}</CustomButton>
          </div>
        </ConfirmResetCartModalWrapper>
      </Modal>
    </CartWrapper>
  );
};
