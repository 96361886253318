import styled from "styled-components";
import {CustomModalContentWrapper} from "components/ui/styled/common.styles";
import { ButtonWrapper } from "components/ui/Button";


export const HeaderWrapper = styled.header<{
  displaySearchResults: boolean
}>`
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: ${({displaySearchResults}) => displaySearchResults ? "20px 40px 4px" : "20px 40px 33px"};
  height: auto;

  @media (max-width: 760px) {
    padding: ${({displaySearchResults}) => displaySearchResults ? "20px 18px 4px" : "20px 18px 33px"};
    flex-direction: column;
    align-items: flex-start;
  }
  
  .logo-and-search {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
  }

  .logo-and-time {
    display: flex;
    align-items: center;
    width: 462px;
    gap: 149px;

    @media (max-width: 1280px) {
      width: 350px;
      gap: 94px;
    }

    @media (max-width: 1160px) {
      width: 270px;
      gap: 40px;
    }
  }
  
  .logo-and-time-and-qr {
    display: flex;
    align-items: center;
    gap: 51px;

    @media (max-width: 1280px) {
      gap: 38px;
    }

    @media (max-width: 1160px) {
      gap: 22px;
    }
  }
  
  .current-date-and-time {
    ${({ theme: { fonts } }) => fonts.subheaderSBolder};
    width: 120px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.white};
  }
`

export const HeaderLogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const HeaderTerminalNumberWrapper = styled.div<{
  micromarketEnabled: boolean
}>`
  display: flex;
  width: 161px;
  justify-content: center;
  align-content: center;
  cursor: ${({micromarketEnabled}) => micromarketEnabled ? "pointer" : "auto"};
  
  //margin-left: 62px;
  
  gap: 20px;
  
  span {
    padding: 0 16px;
    display: flex;
    align-items: center;

    color: ${({ theme: { colors } }) => colors.white};
    
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const HeaderSearchbarWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  //padding: 0 24px;
  padding: 0 73px;

  @media (max-width: 1620px) {
    padding: 0 73px;
  }
  
  @media (max-width: 1440px) {
    padding: 0 53px;
  }

  @media (max-width: 1280px) {
    padding: 0 40px;
  }

  @media (max-width: 1160px) {
    padding: 0 24px;
  }
  
  @media (max-width: 1080px) {
    padding: 0 24px;
  }
  //
  //@media (max-width: 810px) {
  //  width: calc(73% - 80px);
  //}

  @media (max-width: 760px) {
    justify-content: flex-end;
  }
`;

export const HeaderButtonWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  
  .user-name {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  ${ButtonWrapper}  {
    .white-button {
      padding-block: 8px;
      padding-inline: 12px;
      border-radius: 12px;

      ${({ theme: { fonts } }) => fonts.captionXS};
    }
  }
  
  .card-link {
    line-height: 20px;
    padding-inline-end: 16px;
    cursor: pointer;
  }
  
  .rounded-button {
    background: rgba(0, 0, 0, 0.2);
    width: 42px;
    height: 42px;
    border-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    img {
      height: 19px;
    }
  }
  
  .language-text {
    font-family: BasisGrotesquePro;
    font-size: 17px;
    font-weight: 200;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: center;
  }
`;

export const HeaderButtonLangWrapper = styled(HeaderButtonWrapper)`
  justify-content: flex-start;
`

export const HeaderModalWrapper = styled(CustomModalContentWrapper)`
  padding: 50px 72px 160px;
  border-radius: 40px;

  @media (max-width: 1090px) {
    padding: 50px 24px 160px;
  }


  &.bonus-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 584px;
    
    .modal-header {
      margin-bottom: 24px;
    }
    
    .modal-text {
      white-space: break-spaces;
      text-align: center;
      margin-bottom: 40px;
      min-width: 440px;
      
      &.error-message {
        min-height: 72px;
        white-space: pre-wrap;
        color: ${({ theme: { colors } }) => colors.red};
      }
      
      &.first-banner-text {
        //line-height: 12px;
      }
    }

    .red-text {
      color: ${({ theme: { colors } }) => colors.red};
    }
    
    .bonus-banner {
      margin: 32px 0;
      background: url('images/bonus-back.png');
      border-radius: 24px;
      position: relative;
      height: 248px;
      background-size: cover;
      padding: 32px;
      
      display: flex;
      justify-content: space-between;
      width: 100%;
      
      .banner-header {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: ${({ theme: { colors } }) => colors.white};
        margin-bottom: 24px;
      }
      
      .banner-text {
        ${({ theme: { fonts } }) => fonts.subheaderS};
        color: ${({ theme: { colors } }) => colors.white};
      }
      
      .bonus-text-wrapper {
        max-width: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      .qr-code-image {
        background-color: white;
        border-radius: 22px;
        width: 184px;
        height: 184px;
        
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  &.term-of-use-info {
    padding: 0 8px;
    max-height: 780px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .term-of-use-header {
      padding: 50px 64px 24px;
    }
    .term-of-use-content {
      padding: 0 64px 136px;
      overflow: auto;

      @media (max-width: 1090px) {
        padding: 0 24px 136px;
      }
      
      .modal-subheader {
        display: block;
        margin-bottom: 12px;
      }
      
      p {
        margin-bottom: 44px;
      }

      p:last-child {
        margin-bottom: 0;
      }
      
      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 100px;
      }
      
      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 40px;
      }
    }
  }
  
  &.contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;  
    width: auto;

    .modal-header {
      margin-bottom: 32px;
    }
    
    .success-email-sending {
      height: 192px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: break-spaces;
      text-align: center;
      
      color: ${({ theme: { colors } }) => colors.gray};
      ${({ theme: { fonts } }) => fonts.captionS};
    }
    
    .contact-block {
      background: ${({ theme: { colors } }) => colors.lightGray};
      border-radius: 16px;
      padding: 6px 12px;
      margin-bottom: 8px;

      width: 440px;
      min-height: 96px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 575px) {
        width: 100%;
      }
      
      &.call-contact {
        padding: 16px 24px;
      }
      
      &.large {
        height: 192px;
        padding: 16px 24px;
        position: relative;
        
        textarea {
          resize: none;
        }
        
        .contact-send-icon {
          position: absolute;
          bottom: 20px;
          inset-inline-end: 20px;
          
          height: 24px;
          width: 26px;
          cursor: pointer;
          
          svg {
            height: 100%;
            width: 100%;
          }
        }
        
        .go-input {
          height: 100%; 
          width: 100%;
          padding-inline-end: 20px;
          ${({ theme: { fonts } }) => fonts.subheaderS};
        }
      }
    }
    
    .contact-qr-code {
      width: 84px;
      height: 84px;

      svg {
        width: 84px!important;
        height: 84px!important;
      }
      
      //image {
      //  width: 18px;
      //  height: 18px;
      //}
      
      img {
        width: 100%;
        height: 100%;
      }
    }
    
    .contact-link {
      ${({ theme: { fonts } }) => fonts.captionS};
      text-align: right;
      color: ${({ theme: { colors } }) => colors.gray};
    }
    
    .contact-text {
      ${({ theme: { fonts } }) => fonts.captionS};

      color: ${({ theme: { colors } }) => colors.textBlack};
    }
  }
  
  &.terminal-info {
    max-width: 584px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 72px 166px;

    .modal-header {
      margin-bottom: 24px;
    }

    .modal-text {
      margin-bottom: 40px;
      text-align: center;
      white-space: pre-wrap;
    }
    
    .terminal-info-content {
      width: 396px;
      height: 396px;
    }
  }

  @media (max-width: 1090px) {
    width: 100%;
  }
`
